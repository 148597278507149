import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'
import Layout from '../layouts'
import SEO from '../components/seo'
import BG from '../components/bg'
import { Button } from '../components/button'
import ProtocolData from '../components/protocolData'
import { useDarkMode } from '../contexts/Application'
import { CardBGImage, CardGlimmerImage } from '../components/utils'

import RSOTGlimmer from '../images/rsot_glimmer.inline.svg'
// import PinkGlimmer from '../images/pink_glimmer.inline.svg'
// import Twitter from '../images/twitter.inline.svg'
// import Github from '../images/github.inline.svg'
// import Discord from '../images/discord.inline.svg'
// import DevImage from '../images/developer.png'
// import GovImage from '../images/governance.png'
// import AppsImage from '../images/apps.png'
import Twitter from '../images/twitter_240.inline.svg'
import Github from '../images/github_240.inline.svg'
import Telegram from '../images/telegram_app_240.inline.svg'
import Discord from '../images/discord_240.inline.svg'
import DevImage from '../images/landscape.png'
import GovImage from '../images/wheat.png'
import AppsImage from '../images/partner_apps.png'

const BGCard = styled.span`
  width: 100vw;
  height: 100vh;
  max-height: 1220px;
  user-select: none;
  background-repeat: no-repeat;
  background: ${({ theme }) => theme.heroBG};
  background-size: contain;
  mix-blend-mode: overlay;

  @media (max-width: 960px) {
    width: 100vw;
    height: 100vh;
  }
`

const StyledBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.buttonBorder};
  @media (max-width: 960px) {
    margin-bottom: 0;
    padding: 1rem;
    padding-bottom: 8rem;
  }
`

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  will-change: transform;
  align-items: flex-start;
  height: 80vh;
  margin-bottom: 4rem;
`

const StyledBodyTitle = styled.h1`
  font-size: 56px;
  white-space: wrap;
  overflow-wrap: normal;
  @media (max-width: 1024px) {
    margin: 2rem 0 0rem 0;
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: 2rem 0 2rem 0;
    font-weight: 500;
    text-align: left;
    font-size: 58px;
  }

  @media (max-width: 440px) {
    font-weight: 500;
    text-align: left;
    font-size: 52px;
  }
`
const StyledBodySubTitle = styled.h2`
  max-width: 720px;
  line-height: 125%;
  font-weight: 400;
  text-align: left;

  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledBodySubText = styled.h3`
  max-width: 960px;
  line-height: 140%;
  opacity: 0.8;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledSectionTitle = styled.h3`
  max-width: 960px;
  line-height: 140%;
  font-size: 32px;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledExternalLink = styled.a`
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  :focus {
    outline: 0;
    opacity: 0.9;
  }
  :hover {
    * {
      color: ${({ theme }) => theme.colors.link};
    }
  }
`

const StyledSocialRow = styled.nav`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  & > *:not(:first-of-type) {
    margin-top: 0;
    margin-left: 16px;
  }
`

const StyledItemRow = styled.nav`
  display: flex;
  flex-direction: column;

  margin: 0rem;
  & > *:not(:first-of-type) {
    margin-top: 12px;
  }
  @media (min-width: 960px) {
    flex-direction: row;
    & > * {
      margin-bottom: 12px;
    }
    & > *:not(:first-of-type) {
      margin-top: 0;
      margin-left: 12px;
    }
  }
`

const StyledItemColumn = styled.nav`
  display: flex;
  flex-direction: column;

  & > *:not(:last-of-type) {
    margin-bottom: 12px;
  }
`

const StyledRSOTGlimmer = styled(RSOTGlimmer)`
  margin: 0;
  width: 48px;
  height: 48px;
  position: relative;
  top: -24px;
  right: -32px;
  margin-left: -50px;
  margin-right: 2px;
  transition: transform 0.2s linear;
  :hover {
    transform: rotate(-10deg);
  }
`

const StyledTwitter = styled(Twitter)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  width: 24px;
  height: 24px;
`

const StyledDiscord = styled(Discord)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  width: 24px;
  height: 24px;
`

const StyledGithub = styled(Github)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  width: 24px;
  height: 24px;
`

const StyledTelegram = styled(Telegram)`
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  width: 24px;
  height: 24px;
`

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.cardBG};
  border: 1px solid ${({ theme }) => theme.buttonBorder};
  padding: 2rem;
  border-radius: 24px;
  box-shadow: ${({ theme }) => theme.shadows.huge};
`

const HideSmall = styled.span`
  @media (max-width: 960px) {
    display: none;
  }
`

const StyledTradeLink = styled.a`
  padding: 0.25rem 0.75rem;
  background-color: ${({ theme }) => theme.textColor};
  text-decoration: none;
  color: ${({ theme }) => theme.invertedTextColor};
  border-radius: 12px;
  display: inline-block;
  font-weight: 500;
  width: 100%;
  width: min-content;
  white-space: nowrap;
  border: 1px solid transparent;
  box-shadow: ${({ theme }) => theme.shadows.small};
  // display: none;

  :hover,
  :focus {
    border: 1px solid white;
  }
  @media (max-width: 960px) {
    display: inline-block;
  }
`
const IndexPage = props => {
  const isDark = useDarkMode()

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      banner: file(relativePath: { eq: "Banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      grants: file(relativePath: { eq: "unigrants.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      discord: file(relativePath: { eq: "discord.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter: file(relativePath: { eq: "twitter.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reddit: file(relativePath: { eq: "reddit.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      discourse: file(relativePath: { eq: "discourse.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      devs: file(relativePath: { eq: "devs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout path={props.location.pathname}>
      <BGCard>
        {/* <CardNoise /> */}
        <CardGlimmerImage isDark={isDark} />
        <CardBGImage isDark={isDark} />
        {/* <CardFade /> */}
      </BGCard>
      <SEO
        title="Home"
        path={props.location.pathname}
        description={'Democratise lending and Liberalise AI machine trading.'}
      />
      <StyledBody>
        <StyledTitle>
          <StyledBodyTitle>
            <span style={{ fontWeight: 'bold', color: '#3325A0' }}>RISSOTTO</span>
            <StyledRSOTGlimmer />
            <span style={{ fontWeight: 'normal', color: '#333333' }}> protocol</span>
          </StyledBodyTitle>
          <StyledBodySubTitle>
            {'Democratise lending and Liberalise AI machine trading.'}
          </StyledBodySubTitle>

          <StyledItemRow>
            <StyledTradeLink
              style={{
                background: `linear-gradient(128.17deg, #F7B200 -14.78%, #FFD700 110.05%)`,
                color: 'white'
              }}
              href="https://app.rissotto.finance/swap?outputCurrency=0xBC41467d4182D9B9fCDB95C7756e471Cc97Cc028"
            >
              Buy on DEX
            </StyledTradeLink>
            <StyledTradeLink
              style={{
                background: `linear-gradient(128.17deg, #F7B200 -14.78%, #FFD700 110.05%)`,
                color: 'white'
              }}
              href="https://presale.rissotto.finance"
            >
              Buy on Presale
            </StyledTradeLink>
          </StyledItemRow>
          <StyledSocialRow>
            <a href="https://twitter.com/RissottoUCLP" rel="noopener noreferrer" target="_blank">
              <StyledTwitter />
            </a>
            {/* <a href="https://github.com/Uniswap/">
              <StyledGithub />
            </a> */}
            <a href="https://t.me/rissotto_finance" rel="noopener noreferrer" target="_blank">
              <StyledTelegram />
            </a>
            <a href="https://github.com/RissottoFinance/" rel="noopener noreferrer" target="_blank">
              <StyledGithub />
            </a>
            <a href="https://discord.gg/gw996rsPdX" rel="noopener noreferrer" target="_blank">
              <StyledDiscord />
            </a>
          </StyledSocialRow>
        </StyledTitle>
        <EcosystemSection data={data} props={props} />
        <HideSmall>
          <StyledSectionHeader>
            {'PROJECTED TARGETS →'}
          </StyledSectionHeader>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: '4rem 0 3rem 0'
            }}
          >
            <ProtocolData />
          </div>
        </HideSmall>
        <DeveloperSection data={data} props={props} />
        <HappeningSection />
      </StyledBody>
      <BG />
    </Layout>
  )
}

export default IndexPage

const StyledSectionHeader = styled.h1`
  font-size: 20px;
  white-space: wrap;
  overflow-wrap: normal;
  max-width: 900px;
  font-weight: 500;

  a {
    color: ${({ theme }) => theme.textColor};
  }

  @media (max-width: 960px) {
    width: 100%;
    /* font-size: 2rem; */
    line-height: 2.5rem;
    max-width: 600px;
    margin-top: 4rem;
  }
  @media (max-width: 640px) {
    width: 100%;
    font-weight: 400;
    margin-top: 4rem;
    text-align: left;
  }
`

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  @media (max-width: 640px) {
    margin: 0;
  }
`

const StyledResource = styled.div`
  margin-right: 12px;
  margin-top: 12px;
  @media (max-width: 960px) {
    margin-bottom: 12px;
    margin-right: 0px;
  }
`

export const DeveloperCard = styled(StyledCard)`
  mix-blend-mode: ${({ isDark }) => (isDark ? 'overlay' : 'lighten')};
  background: url(${DevImage});
  // color: white;
  background-size: cover;
  background-repeat: no-repeat;
`

export const GovernanceCard = styled(StyledCard)`
  mix-blend-mode: ${({ isDark }) => (isDark ? 'overlay' : 'lighten')};
  background: url(${GovImage});
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 12px;
  @media (max-width: 960px) {
    margin-bottom: 12px;
    margin-right: 0px;
  }
`

export const AppsCard = styled(StyledCard)`
  background: url(${AppsImage});
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 12px;
  width: 100%;
  min-height: 290px;
  max-width: 590px;

  h1 {
    font-size: 48px;
    font-weight: 700;
    margin: 0;
    margin-bottom: 0.25rem;
  }

  p {
    opacity: 0.6;
    font-size: 20px;
    font-weight: 300;
  }

  @media (max-width: 960px) {
    margin-bottom: 12px;
    margin-right: 0px;
    max-width: unset;
  }
`

export const GrantsCard = styled(StyledCard)`
  max-width: 375px;
  @media (max-width: 960px) {
    max-width: unset;
  }
`

const EcosystemSection = () => {
  return (
    <StyledSection>
      <StyledItemRow>
        <span>
          <StyledSectionHeader>{'RISSOTTO ECOSYSTEM →'}</StyledSectionHeader>
          <StyledSectionTitle> Power to loan, ability to recover</StyledSectionTitle>
          <StyledBodySubText style={{ marginRight: '48px' }}>
            A liberalised, democratised and transparent crypto ecosystem where users take charge of their own finances through its <StyledExternalLink href='/whitepaper.pdf' target='_blank'>Un-collateralized Lending Protocol (UCLP)</StyledExternalLink> where recovery of defaulted loans is now possible; while capitalising on signal-driven AI crypto leveraged auto trading for sustainability of gains and operational transparency.
          </StyledBodySubText>
        </span>
        <AppsCard>
          <h1>30+</h1>
          <p>DeFi Integrations</p>
        </AppsCard>
      </StyledItemRow>
    </StyledSection>
  )
}

const DeveloperSection = () => {
  return (
    <>
      <StyledSection>
        <StyledSectionHeader>{'PARTNERSHIP →'}</StyledSectionHeader>
        <StyledItemRow>
          <DeveloperCard
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '100%' }}
          >
            <StyledSectionTitle>Interlinked by trust.</StyledSectionTitle>
            <StyledBodySubTitle style={{ fontSize: '20px' }}>
              Growing platforms via partnering with Rissotto.
            </StyledBodySubTitle>

            <Button href="/partnership">
              <p style={{ margin: 0 }}>
                {' '}
                Find out more ↗
              </p>
            </Button>
          </DeveloperCard>
          {/* <GrantsCard>
            <StyledProductImage fadeIn={false} fluid={props.data.grants.childImageSharp.fluid} />
            <StyledBodySubTitle>Apply for the Uniswap Developer Grants Program</StyledBodySubTitle>
            <p>
              Get paid to build the future of finance. Uniswap Governance offers grant funding for people building apps,
              tools, and activities on the Uniswap Protocol.
            </p>
            <Button href="https://unigrants.org/" outlined>
              <p style={{ margin: 0 }}>Learn more ↗</p>
            </Button>
          </GrantsCard> */}
        </StyledItemRow>
      </StyledSection>

      <StyledSection>
        <StyledSectionHeader>{'PROJECT RISSOTTO →'}</StyledSectionHeader>
        <StyledItemRow>
          <span>
            <GovernanceCard style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <span>
                <StyledSectionTitle>The Future</StyledSectionTitle>
                <StyledBodySubTitle style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  The Rissotto Protocol&apos;s mission is to Democratise lending and Liberalise AI machine trading.
                </StyledBodySubTitle>
                <StyledBodySubTitle style={{ fontSize: '20px' }}>
                  We disrupt and open-up further possibilities in this untapped space, with a staunch belief that everyone has their rights to finance and chart their own financial endeavours in an innovative, transparent and secure environment.
                </StyledBodySubTitle>
              </span>

              <Button href="/features" outlined>
                <p style={{ margin: 0 }}>Read more </p>
              </Button>
            </GovernanceCard>

            <StyledResource>
              <StyledItemRow>
                <Button style={{ borderRadius: '20px', width: '100%' }} href="/whitepaper.pdf" target="_blank">
                  <div style={{ padding: '0.5rem' }}>
                    <StyledBodySubTitle style={{ marginBottom: '0.25rem' }}>
                      Whitepaper <span style={{ fontSize: '1.5rem', float: 'right' }}>↗</span>
                    </StyledBodySubTitle>
                  </div>
                </Button>
                <Button style={{ borderRadius: '20px', width: '100%' }} href="https://rissotto.gitbook.io/rissottofinance/" target="_blank">
                  <div style={{ padding: '0.5rem' }}>
                    <StyledBodySubTitle style={{ marginBottom: '0.25rem' }}>
                      GitBook <span style={{ fontSize: '1.5rem', float: 'right' }}>↗</span>
                    </StyledBodySubTitle>
                  </div>
                </Button>
              </StyledItemRow>
            </StyledResource>
          </span>
          <StyledItemColumn style={{ display: 'flex', flexDirection: 'column' }}>
            <Button style={{ borderRadius: '20px' }} href="/features#features" outlined>
              <div style={{ padding: '1rem' }}>
                <StyledBodySubTitle style={{ marginBottom: '0.25rem' }}>
                  Best in Class <span style={{ fontSize: '16px' }}>↗</span>
                </StyledBodySubTitle>
                <p style={{ textAlign: 'left', margin: '0', opacity: '0.6', fontSize: '16px', fontWeight: 400 }}>
                  Streamline Supply Chain, improve Traceability, Simplify Trade & improve Financial Transactions.
                </p>
              </div>
            </Button>
            <Button style={{ borderRadius: '20px', width: '100%' }} href="/compare" outlined>
              <div style={{ padding: '1rem' }}>
                <StyledBodySubTitle style={{ marginBottom: '0.25rem' }}>
                  How do we fare? <span style={{ fontSize: '16px' }}>↗</span>
                </StyledBodySubTitle>
                <p style={{ textAlign: 'left', margin: '0', opacity: '0.6', fontSize: '16px', fontWeight: 400 }}>
                  Compare us with the industry leaders.
                </p>
              </div>
            </Button>
            <Link to={'/features#earn'}>
              <Button style={{ borderRadius: '20px', width: '100%' }} outlined>
                <div style={{ padding: '1rem' }}>
                  <StyledBodySubTitle style={{ marginBottom: '0.25rem' }}>
                    How do I earn? <span style={{ fontSize: '16px' }}>↗</span>
                  </StyledBodySubTitle>
                  <p style={{ textAlign: 'left', margin: '0', opacity: '0.6', fontSize: '16px', fontWeight: 400 }}>
                    AI trading? Sniper bot? Lending?
                  </p>
                </div>
              </Button>              
            </Link>
            {/* <Button style={{ width: '100%', borderRadius: '20px' }} href="https://app.uniswap.org/#/vote" outlined>
              <div style={{ padding: '1rem' }}>
                <StyledBodySubTitle style={{ marginBottom: '0.25rem' }}>
                  Governance Portal <span style={{ fontSize: '16px' }}>↗</span>
                </StyledBodySubTitle>
                <p style={{ textAlign: 'left', margin: '0', opacity: '0.6', fontSize: '16px', fontWeight: 400 }}>
                  Vote on official Uniswap governance proposals and view past proposals.{' '}
                </p>
              </div>
            </Button> */}
          </StyledItemColumn>
        </StyledItemRow>
      </StyledSection>
    </>
  )
}

const HappeningSection = () => {
  return (
    <StyledSection>
      <StyledItemRow>
        <span>
          <StyledSectionHeader>{'PROGRESS →'}</StyledSectionHeader>
          <StyledSectionTitle> What&apos;s Been Happening so Far?</StyledSectionTitle>
          <StyledBodySubText style={{ marginRight: '48px' }}>
            Our team behind Rissotto has been up to quite a bit since we began in early 2021. The most significant <StyledExternalLink href='/roadmap'>updates</StyledExternalLink> soon would consist of our Initial Farm Offering&apos;s, Lending &amp; Borrowing, Spot Trading, Sniper Bot plus Governance and global <StyledExternalLink href='/partnership'>partnerships</StyledExternalLink> with other lending platforms.
          </StyledBodySubText>
          <StyledBodySubText style={{ marginRight: '48px' }}>
            The team has recently completed the entire <StyledExternalLink href='https://app.rissotto.finance'>DEX</StyledExternalLink> ecosystem, and is working hard towards our next milestone - <StyledExternalLink href='/whitepaper.pdf' target='_blank'>UCLP (Un-Collateralised Lending Protocol)</StyledExternalLink>.
          </StyledBodySubText>
          <StyledBodySubText style={{ marginRight: '48px' }}>
            We are also looking towards creating new Rissotto Community and continuously strengthening them by adding more assets and farms, adjusting yields, and dictating deflationary burns if necessary.
          </StyledBodySubText>
        </span>
        <StyledCard>
          <StyledSectionHeader>{'FINAL THOUGHTS →'}</StyledSectionHeader>
          <StyledSectionTitle> What&apos;s Rissotto&apos;s Future?</StyledSectionTitle>
          <StyledBodySubText style={{ marginRight: '48px' }}>
            The team is working hard towards making Rissotto as one of the most preferred AMMs and on-chain farming and trading ecosystem projects.  We are aiming for a TVL of over $1 Billion and a daily volume of ~100 million dollars within the next 1 year.
          </StyledBodySubText>
          <StyledBodySubText style={{ marginRight: '48px' }}>
            <StyledExternalLink href='/performance'>How do we perform</StyledExternalLink> &amp; <StyledExternalLink href='/roadmap#now'>where are we now?</StyledExternalLink>
          </StyledBodySubText>
          <StyledBodySubText style={{ marginRight: '48px' }}>
            With the rapid expansion of the DeFi space on both Ethereum and BSC, Rissotto is looking to continuously innovate and invigorate; and be institutionalised as the de-facto lending protocol for uncollateralised lending for all traditional financial institutes and intermediaries looking to expand via the crypto space, thus, overcoming financial inequalities where current borrowers or lenders are discriminated based on their available funds, asset holdings and are treated at the discretion of opaque and arcade banking system.
          </StyledBodySubText>
        </StyledCard>
      </StyledItemRow>
    </StyledSection>
  )
}

